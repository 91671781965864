import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { FormGroup, FormControl } from '@angular/forms';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'checkbox-group-element',
    templateUrl: './checkbox-group.component.html'
})
export class CheckboxGroupComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    @Input() form: FormGroup

    @Input() data: ControlElement

    constructor(
        private parentControl: FormBuilderComponent,
        private communication: ControlCommunicationService,
        private route: ActivatedRoute,
    ) {
        this.communication.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'checklist') {
                const isDictamen =  
                this.form.controls.existente_completa_firmada_orden_inspeccion.value || 
                this.form.controls.existente_completa_firmada_informe_inspeccion.value || 
                this.form.controls.existente_completa_firmada_anexos_inspeccion.value 
                    ? true
                    : false

                const notIsDictamen =
                    this.form.controls.existente_no_completa_orden_inspeccion.value || 
                    this.form.controls.no_existe_orden_inspeccion.value || 
                    this.form.controls.existente_no_completa_informe_inspeccion.value ||
                    this.form.controls.no_existe_informe_inspeccion.value || 
                    this.form.controls.existente_no_completa_anexos_inspeccion.value || 
                    this.form.controls.no_existe_anexos_inspeccion.value 
                        ? true
                        : false

                //console.log("dictamen: ",isDictamen, "no es dictamen: ",notIsDictamen)
                if(isDictamen && notIsDictamen) {
                    this.form.controls.acciones_dictamen.patchValue(false)
                    this.form.controls.acciones_dictamen.disable()

                    this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_inspector.enable()

                    this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_operador.enable()
                } else if(isDictamen && !notIsDictamen) {
                    this.form.controls.acciones_dictamen.patchValue(true)
                    this.form.controls.acciones_dictamen.disable()

                    this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_inspector.disable()

                    this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_operador.disable()
                } else if(!isDictamen && notIsDictamen) {
                    this.form.controls.acciones_dictamen.patchValue(false)
                    this.form.controls.acciones_dictamen.disable()

                    this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_inspector.enable()

                    this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_operador.enable()
                } else {
                    this.form.controls.acciones_dictamen.patchValue(false)
                    this.form.controls.acciones_dictamen.disable()

                    this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_inspector.disable()

                    this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                    this.form.controls.acciones_pedir_informacion_operador.disable()
                }
            } else if (msg.identifier === 'scopes') {
                const variantsScopes = msg.scopes
                const variants = variantsScopes.map((variant) => {
                    if(typeof variant === 'string') {
                        return parseInt(variant)
                    }

                    return variant
                })

                const hasLPO = variants.some(variant => 
                    [
                        0, 1, 2, 3, 4, 5, 6, 7,
                        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
                        40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50
                    ].includes(variant)
                )

                const hasOPR_COR = variants.some(variant => 
                    [
                        8, 9, 10, 11,
                        23, 24, 25, 26, 27, 28, 29, 30, 31,
                        51, 52, 53, 54, 55, 56, 57, 58, 59
                    ].includes(variant)
                )

                const hasUE = variants.some(variant => 
                    [
                        60, 61
                    ].includes(variant)
                )

                // console.log(hasLPO, hasOPR_COR, hasUE, variants)

                if(!hasLPO && this.form.value.hasOwnProperty('lista_productos_lpo')) {
                    this.form.get('lista_productos_lpo')?.patchValue(false)
                    this.form.get('lista_productos_lpo')?.disable()
                }

                if(!hasOPR_COR && this.form.value.hasOwnProperty('lista_productos_cor')) {
                    this.form.get('lista_productos_cor')?.patchValue(false)
                    this.form.get('lista_productos_cor')?.disable()
                }

                if(!hasUE && this.form.value.hasOwnProperty('lista_productos_ue')) {
                    this.form.get('lista_productos_ue')?.patchValue(false)
                    this.form.get('lista_productos_ue')?.disable()
                }

                /* setTimeout(() => {
                    this.communication.emitEvent({
                        identifier: 'scopes',
                        scopes: variants,
                    })
                }, 100) */
            }
        })
    }

    ngOnInit() {
        if (!this.form) {this.form = this.parentControl.formGroup}
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }
        this.attributes.options && this.attributes.options.forEach(option => {
            this.form.addControl(option[0], new FormControl(false))
        })
        this.attributes.options_scopes && this.attributes.options_scopes.forEach(option => {
            this.form.addControl(option[0], new FormControl(false))
        })
        this.attributes.options_multi && this.attributes.options_multi.forEach(option => {
            option.forEach(elem => {
                this.form.addControl(elem, new FormControl(false))
            });
        })
        if (this.attributes.other_option) {
            this.form.addControl(this.attributes.other_option[0], new FormControl(false))
            this.form.addControl(this.attributes.other_option[1], new FormControl(''))
        }
        if (this.attributes.special_option) {
            this.form.addControl(this.attributes.special_option[0], new FormControl(false))
            this.form.addControl(this.attributes.special_option[2], new FormControl(''))
        }
        if (this.attributes.special_option_input) {
            this.form.addControl(this.attributes.special_option_input[0], new FormControl(false))
            this.form.addControl(this.attributes.special_option_input[2], new FormControl(''))
        }
        if (this.attributes.special_option_area) {
            this.form.addControl(this.attributes.special_option_area[0], new FormControl(false))
            this.form.addControl(this.attributes.special_option_area[2], new FormControl(''))
        }
    }

    otherOptValueChanged(event: any, key: string) {
        if (!event.target.checked) {
            this.form.setValue({
                ...this.form.value,
                [key]: ''
            })
        }
    }

    specialOptValueChanged(event: any, key: string) {
        if (!event.target.checked) {
            this.form.setValue({
                ...this.form.value,
                [key]: ''
            })
        }
    }

    specialOptInpValueChanged(event: any, key: string) {
        if (!event.target.checked) {
            this.form.setValue({
                ...this.form.value,
                [key]: ''
            })
        }
    }

    specialOptAreaValueChanged(event: any, key: string) {
        if (!event.target.checked) {
            this.form.setValue({
                ...this.form.value,
                [key]: ''
            })
        }
    }

    multiOptionsIsChecked(values: string[]) {
        for (var i in values) {
            const elem = values[i]
            if (this.form.get(elem)) {
                const formElem = this.form.get(elem).value
                if (formElem) return true
            }
        }
        return false
    }

    validateSelection(values: string[][]) {
        for (var i in values) {
            const elem = values[i][0]
            if (this.form.get(elem)) {
                const formElem = this.form.get(elem).value
                if (formElem) return true
            }
        }
        return false
    }

    change(event, item) {
        // console.log("checkbox: ", item)
        // console.log("value: ", event.target.checked)

        if(['lista_productos_lpo', 'lista_productos_cor', 'lista_productos_ue'].includes(item)) {
            const recordId = this.route.snapshot.params['recordId'];
            const tmpVariants: any = localStorage.getItem("variant"+recordId)
            const variants = tmpVariants.split(",")

            setTimeout(() => {
                this.communication.emitEvent({
                    identifier: 'scopes',
                    scopes: variants,
                })
            }, 100)
        }

        // Values of MTO-FSOL-07
        switch(item) {
            case "verificacion_lpo":
            case "verificacion_opr":
            case "verificacion_cor":
            case "verificacion_ue":
                if(event.target.checked) {
                    //console.log("permitido")

                    this.form.patchValue({
                        verificacion_no_permitido: false
                    })

                    /* setTimeout(() => {
                        this.communication.emitEvent({
                            identifier: 'set-values',
                            form: this.form
                        })
                    }, 100); */
                }
                break
            case "verificacion_no_permitido":
                if(event.target.checked) {
                    //console.log("no permitido")

                    this.form.patchValue({
                        verificacion_lpo: false,
                        verificacion_opr: false,
                        verificacion_cor: false,
                        verificacion_ue: false
                    })

                    /* setTimeout(() => {
                        this.communication.emitEvent({
                            identifier: 'set-values',
                            form: this.form
                        })
                    }, 100); */
                }
                break
            default:
                break
        }

        this.checklistValidation(item)
    }

    checklistValidation(item) {
        // Values of MTO-FRYD-01
        const checklist = [
            // Check dictamen and disable other options
            "existente_completa_firmada_orden_inspeccion",
            "existente_completa_firmada_informe_inspeccion",
            "existente_completa_firmada_anexos_inspeccion",
            // Disable dictamen and enable other options
            "existente_no_completa_orden_inspeccion",
            "no_existe_orden_inspeccion",
            "existente_no_completa_informe_inspeccion",
            "no_existe_informe_inspeccion",
            "existente_no_completa_anexos_inspeccion",
            "no_existe_anexos_inspeccion"
        ]
        if(checklist.includes(item)) {
            const isDictamen =  
                this.form.controls.existente_completa_firmada_orden_inspeccion.value || 
                this.form.controls.existente_completa_firmada_informe_inspeccion.value || 
                this.form.controls.existente_completa_firmada_anexos_inspeccion.value 
                    ? true
                    : false

            const notIsDictamen =
                this.form.controls.existente_no_completa_orden_inspeccion.value || 
                this.form.controls.no_existe_orden_inspeccion.value || 
                this.form.controls.existente_no_completa_informe_inspeccion.value ||
                this.form.controls.no_existe_informe_inspeccion.value || 
                this.form.controls.existente_no_completa_anexos_inspeccion.value || 
                this.form.controls.no_existe_anexos_inspeccion.value 
                    ? true
                    : false

            //console.log("dictamen: ",isDictamen, "no es dictamen: ",notIsDictamen)
            if(isDictamen && notIsDictamen) {
                this.form.controls.acciones_dictamen.patchValue(false)
                this.form.controls.acciones_dictamen.disable()

                this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                this.form.controls.acciones_pedir_informacion_inspector.enable()

                this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                this.form.controls.acciones_pedir_informacion_operador.enable()
            } else if(isDictamen && !notIsDictamen) {
                this.form.controls.acciones_dictamen.patchValue(true)
                this.form.controls.acciones_dictamen.disable()

                this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                this.form.controls.acciones_pedir_informacion_inspector.disable()

                this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                this.form.controls.acciones_pedir_informacion_operador.disable()
            } else if(!isDictamen && notIsDictamen) {
                this.form.controls.acciones_dictamen.patchValue(false)
                this.form.controls.acciones_dictamen.disable()

                this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                this.form.controls.acciones_pedir_informacion_inspector.enable()

                this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                this.form.controls.acciones_pedir_informacion_operador.enable()
            } else {
                this.form.controls.acciones_dictamen.patchValue(false)
                this.form.controls.acciones_dictamen.disable()

                this.form.controls.acciones_pedir_informacion_inspector.patchValue(false)
                this.form.controls.acciones_pedir_informacion_inspector.disable()

                this.form.controls.acciones_pedir_informacion_operador.patchValue(false)
                this.form.controls.acciones_pedir_informacion_operador.disable()
            }
        }
    }

}