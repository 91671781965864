import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Scopes {
  private scopesVersions = {
    version1: [
      /* Alcances LPO */
      {
        id: 'LPO_COMERCIALIZACION_PRODUCTOS_AGROPECUARIOS',
        name: 'LPO - Comercialización de Productos de las Actividades Agropecuarias',
        type: 'LPO',
        variant: 0,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PROCESAMIENTO_PRODUCTOS_AGROPECUARIOS',
        name: 'LPO - Procesamiento de Productos de las Actividades Agropecuarias',
        type: 'LPO',
        variant: 1,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ANIMAL_ECOSISTEMAS',
        name: 'LPO - Producción animal de ecosistemas naturales o no domésticos',
        type: 'LPO',
        variant: 2,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ANIMAL_DOMESTICOS',
        name: 'LPO - Producción animal (domésticos)',
        type: 'LPO',
        variant: 3,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: ['LPO_PRODUCCION_VEGETAL', 'OPR_PLANTAS_NO_PROCESADOS'],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL',
        name: 'LPO - Producción Vegetal',
        type: 'LPO',
        variant: 4,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [
          'OPR_PLANTAS_NO_PROCESADOS',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_FUNGI',
        name: 'LPO - Productos de la Clase Fungi',
        type: 'LPO',
        variant: 5,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_INSECTA',
        name: 'LPO - Productos de la Clase Insecta',
        type: 'LPO',
        variant: 6,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_RECOLECCION',
        name: 'LPO - Productos vegetales de recolección silvestre',
        type: 'LPO',
        variant: 7,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      /* Alcances OPR */
      {
        id: 'OPR_ALIMENTO_GANADO',
        name: 'OPR - Producción de alimentos para el ganado',
        type: 'OPR',
        variant: 8,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'OPR_PLANTAS_NO_PROCESADOS',
        name: 'OPR - Producción agrícola',
        type: 'OPR',
        variant: 9,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: ['LPO_PRODUCCION_VEGETAL', 'LPO_PRODUCCION_ANIMAL_DOMESTICOS'],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'OPR_PRODUCTOS_AGRICOLAS_GANADEROS_TRANSFORMADOS',
        name: 'OPR - Productos procesados',
        type: 'OPR',
        variant: 10,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'OPR_PRODUCTOS_AGRICOLAS_GANADEROS_TRANSFORMADOS_ALIMENTACION',
        name: 'OPR - Producción especializada (invernaderos, miel de abeja, recolección, germinados)',
        type: 'OPR',
        variant: 11,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
    ],
    version2: [
      /* Alcances LPO */
      {
        id: 'LPO_PRODUCCION_VEGETAL_V2',
        name: 'LPO - Producción vegetal',
        type: 'LPO',
        variant: 12,
        haveSubdivision: true,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [
          'COR_PRODUCCION_AGRICOLA',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V2',
        ],
        hasRelation: [''],
        subdivisions: [
          'LPO_PRODUCCION_VEGETAL_GRANOS',
          'LPO_PRODUCCION_VEGETAL_ESTRUCTURAS',
          'LPO_PRODUCCION_VEGETAL_GERMINADOS',
        ],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL_GRANOS',
        name: 'Granos básicos y frutales',
        type: 'LPO',
        variant: 12,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [
          'LPO_PRODUCCION_VEGETAL_V2',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V2',
          'COR_PRODUCCION_AGRICOLA',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL_ESTRUCTURAS',
        name: 'Estructuras y contenedores',
        type: 'LPO',
        variant: 13,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL_GERMINADOS',
        name: 'Germinados',
        type: 'LPO',
        variant: 14,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_RECOLECCION_V2',
        name: 'LPO - Productos vegetales de recolección silvestre',
        type: 'LPO',
        variant: 15,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V2',
        name: 'LPO - Producción animal (domésticos)',
        type: 'LPO',
        variant: 16,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [
          'LPO_PRODUCCION_VEGETAL_V2',
          'LPO_PRODUCCION_VEGETAL_GRANOS',
          'COR_PRODUCCION_AGRICOLA',
        ],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ANIMAL_ECOSISTEMAS_V2',
        name: 'LPO - Producción animal de ecosistemas naturales o no domésticos',
        type: 'LPO',
        variant: 17,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_INSECTA_V2',
        name: 'LPO - Productos de la clase insecta',
        type: 'LPO',
        variant: 18,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ACUICOLA',
        name: 'LPO - Producción animal acuícola',
        type: 'LPO',
        variant: 19,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_FUNGI_V2',
        name: 'LPO - Productos de la clase fungi',
        type: 'LPO',
        variant: 20,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PROCESAMIENTO_PRODUCTOS_AGROPECUARIOS_V2',
        name: 'LPO - Procesamiento de productos de las actividades agropecuarias',
        type: 'LPO',
        variant: 21,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_COMERCIALIZACION_PRODUCTOS_AGROPECUARIOS_V2',
        name: 'LPO - Comercialización de productos de las actividades agropecuarias',
        type: 'LPO',
        variant: 22,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      /* Alcances COR */
      {
        id: 'COR_PRODUCCION_AGRICOLA',
        name: 'COR/USCOEA - Producción agrícola',
        type: 'COR',
        variant: 23,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [
          'LPO_PRODUCCION_VEGETAL_V2',
          'LPO_PRODUCCION_VEGETAL_GRANOS',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V2',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ALIMENTO_GANADO',
        name: 'COR/USCOEA - Producción de alimentos para el ganado',
        type: 'COR',
        variant: 24,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA',
        name: 'COR/USCOEA - Producción especializada',
        type: 'COR',
        variant: 25,
        haveSubdivision: true,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [
          'COR_PRODUCCION_ESPECIALIZADA_INVERNADEROS',
          'COR_PRODUCCION_ESPECIALIZADA_APICULTURA',
          'COR_PRODUCCION_ESPECIALIZADA_VEGETALES',
          'COR_PRODUCCION_ESPECIALIZADA_GERMINADOS',
        ],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_INVERNADEROS',
        name: 'Invernaderos y contenedores',
        type: 'COR',
        variant: 25,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: ['COR_PRODUCCION_ESPECIALIZADA'],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_APICULTURA',
        name: 'Apicultura',
        type: 'COR',
        variant: 26,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_VEGETALES',
        name: 'Vegetales de recolección silvestre',
        type: 'COR',
        variant: 27,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_GERMINADOS',
        name: 'Germinados',
        type: 'COR',
        variant: 28,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCTOS_PROCESADOS',
        name: 'COR/USCOEA - Productos procesados',
        type: 'COR',
        variant: 29,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCTOS_ORGANICOS',
        name: 'COR/USCOEA - Productos orgánicos',
        type: 'COR',
        variant: 30,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: true,
        sameAs: [
          'LPO_PRODUCCION_VEGETAL_V2',
          'LPO_PRODUCCION_VEGETAL_GRANOS',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V2',
          'COR_PRODUCCION_AGRICOLA',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_EMPAQUE_ETIQUETADO_PRODUCTOS_ORGANICOS',
        name: 'COR/USCOEA - Empaque o etiquetado de productos orgánicos',
        type: 'COR',
        variant: 31,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: true,
        sameAs: ['COR_PRODUCTOS_PROCESADOS'],
        hasRelation: [''],
        subdivisions: [''],
      },
    ],
    version3: [
      /* Alcances LPO */
      {
        id: 'LPO_PRODUCCION_VEGETAL_V3',
        name: 'LPO - Producción vegetal',
        type: 'LPO',
        variant: 40,
        haveSubdivision: true,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [
          'COR_PRODUCCION_AGRICOLA_V3',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V3',
          'UE_VEGETALES_Y_NO_TRANSFORMADOS_V3',
        ],
        hasRelation: [''],
        subdivisions: [
          'LPO_PRODUCCION_VEGETAL_GRANOS_V3',
          'LPO_PRODUCCION_VEGETAL_ESTRUCTURAS_V3',
          'LPO_PRODUCCION_VEGETAL_GERMINADOS_V3',
        ],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL_GRANOS_V3',
        name: 'Granos básicos y frutales',
        type: 'LPO',
        variant: 40,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [
          'LPO_PRODUCCION_VEGETAL_V3',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V3',
          'COR_PRODUCCION_AGRICOLA_V3',
          'UE_VEGETALES_Y_NO_TRANSFORMADOS_V3',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL_ESTRUCTURAS_V3',
        name: 'Estructuras y contenedores',
        type: 'LPO',
        variant: 41,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_VEGETAL_GERMINADOS_V3',
        name: 'Germinados',
        type: 'LPO',
        variant: 42,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_RECOLECCION_V3',
        name: 'LPO - Productos vegetales de recolección silvestre',
        type: 'LPO',
        variant: 43,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V3',
        name: 'LPO - Producción animal (domésticos)',
        type: 'LPO',
        variant: 44,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [
          'LPO_PRODUCCION_VEGETAL_V3',
          'LPO_PRODUCCION_VEGETAL_GRANOS_V3',
          'COR_PRODUCCION_AGRICOLA_V3',
          'UE_VEGETALES_Y_NO_TRANSFORMADOS_V3',
        ],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ANIMAL_ECOSISTEMAS_V3',
        name: 'LPO - Producción animal de ecosistemas naturales o no domésticos',
        type: 'LPO',
        variant: 45,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_INSECTA_V3',
        name: 'LPO - Productos de la clase insecta',
        type: 'LPO',
        variant: 46,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCCION_ACUICOLA_V3',
        name: 'LPO - Producción animal acuícola',
        type: 'LPO',
        variant: 47,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PRODUCTOS_FUNGI_V3',
        name: 'LPO - Productos de la clase fungi',
        type: 'LPO',
        variant: 48,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_PROCESAMIENTO_PRODUCTOS_AGROPECUARIOS_V3',
        name: 'LPO - Procesamiento de productos de las actividades agropecuarias',
        type: 'LPO',
        variant: 49,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'LPO_COMERCIALIZACION_PRODUCTOS_AGROPECUARIOS_V3',
        name: 'LPO - Comercialización de productos de las actividades agropecuarias',
        type: 'LPO',
        variant: 50,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      /* Alcances COR */
      {
        id: 'COR_PRODUCCION_AGRICOLA_V3',
        name: 'COR/USCOEA - Producción agrícola',
        type: 'COR',
        variant: 51,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [
          'LPO_PRODUCCION_VEGETAL_V3',
          'LPO_PRODUCCION_VEGETAL_GRANOS_V3',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V3',
          'UE_VEGETALES_Y_NO_TRANSFORMADOS_V3',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ALIMENTO_GANADO_V3',
        name: 'COR/USCOEA - Producción de alimentos para el ganado',
        type: 'COR',
        variant: 52,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_V3',
        name: 'COR/USCOEA - Producción especializada',
        type: 'COR',
        variant: 53,
        haveSubdivision: true,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [
          'COR_PRODUCCION_ESPECIALIZADA_INVERNADEROS_V3',
          'COR_PRODUCCION_ESPECIALIZADA_APICULTURA_V3',
          'COR_PRODUCCION_ESPECIALIZADA_VEGETALES_V3',
          'COR_PRODUCCION_ESPECIALIZADA_GERMINADOS_V3',
        ],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_INVERNADEROS_V3',
        name: 'Invernaderos y contenedores',
        type: 'COR',
        variant: 53,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: ['COR_PRODUCCION_ESPECIALIZADA_V3'],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_APICULTURA_V3',
        name: 'Apicultura',
        type: 'COR',
        variant: 54,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_VEGETALES_V3',
        name: 'Vegetales de recolección silvestre',
        type: 'COR',
        variant: 55,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCCION_ESPECIALIZADA_GERMINADOS_V3',
        name: 'Germinados',
        type: 'COR',
        variant: 56,
        haveSubdivision: false,
        isSubdivision: true,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCTOS_PROCESADOS_V3',
        name: 'COR/USCOEA - Productos procesados',
        type: 'COR',
        variant: 57,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_PRODUCTOS_ORGANICOS_V3',
        name: 'COR/USCOEA - Productos orgánicos',
        type: 'COR',
        variant: 58,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: true,
        sameAs: [
          'LPO_PRODUCCION_VEGETAL_V3',
          'LPO_PRODUCCION_VEGETAL_GRANOS_V3',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V3',
          'COR_PRODUCCION_AGRICOLA_V3',
          'UE_VEGETALES_Y_NO_TRANSFORMADOS_V3',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'COR_EMPAQUE_ETIQUETADO_PRODUCTOS_ORGANICOS_V3',
        name: 'COR/USCOEA - Empaque o etiquetado de productos orgánicos',
        type: 'COR',
        variant: 59,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: true,
        sameAs: ['COR_PRODUCTOS_PROCESADOS_V3'],
        hasRelation: [''],
        subdivisions: [''],
      },
      /* Alcances UE */
      {
        id: 'UE_VEGETALES_Y_NO_TRANSFORMADOS_V3',
        name: 'UE - Vegetales y productos vegetales no transformados',
        type: 'UE',
        variant: 60,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [
          'COR_PRODUCCION_AGRICOLA_V3',
          'LPO_PRODUCCION_VEGETAL_V3',
          'LPO_PRODUCCION_VEGETAL_GRANOS_V3',
          'LPO_PRODUCCION_ANIMAL_DOMESTICOS_V3',
        ],
        hasRelation: [''],
        subdivisions: [''],
      },
      {
        id: 'UE_PRODUCTOS_AGRAGRIOS_TRANSFORMADOS_ALIMENTACION_V3',
        name: 'UE - Productos agrarios transformados para la alimentación humana',
        type: 'UE',
        variant: 61,
        haveSubdivision: false,
        isSubdivision: false,
        isSubScope: false,
        sameAs: [''],
        hasRelation: [''],
        subdivisions: [''],
      },
    ],
  };

  constructor(){}

  getScopesVersion(version: number) {
    return this.scopesVersions[`version${version}`];
  }

  getAllScopes() {
    const combinedScopes = Object.values(this.scopesVersions).reduce((acc, curr) => acc.concat(curr), []);
    return combinedScopes
  }

  getAllScopesVariants () {
    const allScopes = this.getAllScopes()

    const scopes = allScopes
      .map((scope) => {
        if(scope.haveSubdivision) return null

        if(scope.isSubdivision) {
            const principalScope: any = allScopes.find(item => item.subdivisions.includes(scope.id))
            const scopeName = `${principalScope.name} (${scope.name})`
            return {
              variant: scope.variant,
              scopeName: scopeName,
              scope: scopeName.split('-')[1].trim(),
              type: scopeName.split('-')[0].trim(),
            }
        }

        return {
          variant: scope.variant,
          scopeName: scope.name,
          scope: scope.name.split('-')[1].trim(),
          type: scope.name.split('-')[0].trim(),
        }
      })
      .filter(scope => scope !== null)

    return scopes
  }
}
