export const environment = {
  version: '3.0 - 22/01/2025',                           //VERSION
  docsUrl: 'https://metroecert.com',      //URL BASE DE DOCUMENTOS
  production: false,
  apiUrl: 'https://emetro-api.com'        //PRODUCCION
  //apiUrl: 'https://staging.emetro-api.com'    //STAGE API Updates
  //apiUrl: 'https://europa.emetro-api.com'    //STAGE API UE
  
  // apiUrl: 'http://localhost:5000'       //ENTORNO LOCAL/NGROK
};
