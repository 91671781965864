import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component'
import { CataloguesService } from './../../../../core/services/catalogues.service';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { Scopes } from '../../../../utils/scopes'

@Component({
    selector: 'multiselect-element',
    templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}
    options: {
        name: String,
        value: any
    }[] = []

    @Input() data: ControlElement
    @Input() form: FormGroup

    scopesVariants: any = []

    // inject parent FormBuilder Component in order to access FormGroup
    constructor(
        private parentControl: FormBuilderComponent,
        private communicationService: ControlCommunicationService,
        private route: ActivatedRoute,
        private cataloguesService: CataloguesService,
        private scopes: Scopes,
    ) {
        this.scopesVariants = this.scopes.getAllScopesVariants()

        this.communicationService.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'scopes') {
                const variants = msg.scopes

                let scopeType: string[] = []
                if(this.form.value.hasOwnProperty('lista_productos_lpo') && this.form.value.lista_productos_lpo) scopeType.push('LPO')
                if(this.form.value.hasOwnProperty('lista_productos_cor') && this.form.value.lista_productos_cor) scopeType.push('COR/USCOEA')
                if(this.form.value.hasOwnProperty('lista_productos_ue') && this.form.value.lista_productos_ue) scopeType.push('UE')

                this.options = variants.map(variant => {
                    const scope = this.scopesVariants.find(scope => scope.variant === parseInt(variant) && scopeType.includes(scope.type))
                    if(scope) {
                        return {
                            name: scope.scopeName,
                            value: scope.scope
                        }
                    } else {
                        return null
                    }
                }).filter(variant => variant !== null)

                this.validateSelectedValues()
            } else if(msg.identifier === 'analisis') {
                this.form.patchValue({
                    informe_submuestra_1_analisis: []
                })

                this.options = msg.analisis
            }
        })
    }

    ngOnInit(): void {
        // asign parent formGroup to local formGroup
        if (!this.form) { this.form = this.parentControl.formGroup }
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }

        const recordId = this.route.snapshot.params['recordId'];
        if(recordId) {
            const tmpVariants: any = localStorage.getItem("variant"+recordId)
            const variants = tmpVariants.split(",")
            this.options = variants.map(variant => {
                const scope = this.scopesVariants.find(scope => scope.variant === parseInt(variant))
                return {
                    name: scope.scopeName,
                    value: scope.scope
                }
            })

            this.validateSelectedValues()
        } else if(this.attributes.name === "informe_submuestra_1_analisis" && this.form.value.informe_submuestra_1_laboratorio !== '') {
            var laboratorio = this.form.value.informe_submuestra_1_laboratorio

            this.cataloguesService.getOnlyLaboratories().pipe(first()).subscribe(data => {
                if(data.success) {
                  for (var i in data.data) {
                    const elem = data.data[i]
                    if(elem.Name === laboratorio) {
                      var tests = JSON.parse(elem.Tests)
                      this.options = []
                      for(var i in tests) {
                        const option = tests[i]
                        this.options.push({
                          name: option.name,
                          value: option.name
                        })
                      }

                      break
                    }
                  }
                }
              }, error => {
                console.log("Error al consultar analisis de laboratorio " + laboratorio)
              })
        }

        if(this.attributes.name === 'calculo_cobre_parcela') {
            const inspectionId = this.route.snapshot.params['id'];
            if(inspectionId) {
                const units = JSON.parse(localStorage.getItem(`units_${inspectionId}`) as string)

                this.options = units.map(unit => {
                    console.log(unit)
                    return {
                        name: unit.nombre_unidad_produccion,
                        value: unit.nombre_unidad_produccion
                    }
                })
            }
        }
        
        this.form.addControl(this.attributes.name, new FormControl(""))
    }

    /**
     * Function to validate selected values in form
     * and delete values that not exist in the option values.
     */
    private validateSelectedValues(): void {
        const controlName = this.attributes.name;
        const selectedValues = this.form.get(controlName)?.value;

        if (Array.isArray(selectedValues)) {
            const validValues = selectedValues.filter(value =>
                this.options.some(option => option.value === value)
            );

            if (validValues.length !== selectedValues.length) {
                // Update control with valid values
                this.form.get(controlName)?.setValue(validValues, { emitEvent: false });
            }
        }
    }

    selectedOption = [];
}
